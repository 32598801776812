@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap");
@import url("http://fonts.cdnfonts.com/css/corbel");

* {
  box-sizing: border-box;
  margin: 0;
}


html {
  font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
  text-align: center;
}

body {
  font-size: 1.6rem;
  font-family: "Josefin Sans", "Corbel", sans-serif;
  color: #fff;
  height: 100%;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(25, 212, 245, 0.295); 
  border-radius: 10px; 
}

*::-webkit-scrollbar-thumb {
  background: rgba(70, 214, 240, 0.8); 
  border-radius: 10px; 
  border: 3px solid rgba(0, 0, 0, 0); /* Invisible border for padding */
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6); 
}
*::-webkit-scrollbar-button {
  display: none; 
}

#root {
  height: 100dvh;
  overflow: hidden;
}


.ws {
  display: none;
}

.canvasContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.backgroundCanvas {
  position: absolute; /* Positioning is absolute within the container */
  top: 0;
  left: 0;
  width: 100%; /* Fill the width */
  height: 100%; /* Fill the height */
  z-index: -1; /* Behind other content */
  
}

.container_main-blurb > section:not() {
padding-top: 2rem;
}

h3 {
  font-size: 1.5rem;
  text-align: center;
  text-decoration: double;
  text-shadow: 1px 1px 5px 10px rgba(0,0,0,0.5);
}

p {
  text-align: left;
}



.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: auto;
  z-index: 5;
  /* background: rgba(0,0,0,0.8); */
  background: radial-gradient(circle, rgba(2,0,36,0.5) 0%, rgba(7, 27, 36, 0.5) 35%, rgba(14, 43, 59, 0.8) 100%);

}



h1,
h2,
h3,
h4,
h5,
small,
p {
  color: rgb(236, 253, 253);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

p {
  text-shadow: -1px 2px rgba(0, 0, 0, 0.5);
}

header {
  flex-shrink: 0;
  padding: 1rem;
  text-align: center;
}

h1 {
  text-align: center;
  position: relative;
  font-size: 8rem;
  font-weight: 700;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
  animation-name: titleAnimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  z-index: 1;
  -webkit-text-fill-color: rgba(28, 157, 255, 0.25);
  -webkit-text-stroke: 5px rgba(255, 255, 255, 0);
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    rgb(182, 239, 255) 0%,
    rgba(0, 157, 255, 0.8) 80%,
    rgba(0, 81, 255, 0.8)
  );
}


.container_glass {
  border-radius: 10px;
  background:  linear-gradient(130deg, rgba(251, 252, 255, 0.04) 0%, rgba(255,255,255,0.04)20%, rgba(167, 187, 247, 0.07) 38%, rgba(166, 142,208, 0.05) 65%, rgba(50, 132, 240, 0.05) 85%, rgba(0,51,131,0.08) 100%);
  box-shadow: 5px 5px 10px 1px rgba(80,80,80,0.05), inset -6px 6px 30px 5px rgba(212, 254, 255, 0.05), inset 6px -6px 30px 5px rgba(0,0,0,0.05);
  margin: 0.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
  border:5px rgba(255, 255, 255, 0.02);
  border-style: groove;
}
.container_glass::before {
  content: "";
  filter: blur(30px);
  filter:contrast(200%);
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
}

.container_home {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: stretch;
  padding: 2rem;
  z-index: 5;
  margin-bottom: 2rem;
  height: auto;
  max-height: calc(100dvh - 15dvh - 4rem);
  gap: 2rem;
  overflow-y: auto;
}

.container_main-image {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.mainProfilePhoto {
  border-radius: 2rem;
  box-shadow: 1px 15px 10px 8px rgba(82, 82, 82, 0.2), inset 0 0 15px 5px rgba(255,255,255,1);
  border:5px rgba(192, 237, 240, 0.342);
  border-style: groove;
  margin: 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  margin-top: 5rem;

}

.container_main-blurb {
  flex-grow: 1;
  flex-basis:60%;
  justify-content:flex-start;
  align-items: flex-start;
  height: auto;
  padding: 2rem 3rem;
  margin: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  
}


.container_external-links {
  display: flex;
  bottom: 1rem;
  left: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 32px;
  height: 15dvh;
  width: 100%;
  margin: 0;
  flex-grow: 0;
  z-index: 10;
  padding: 1rem;
  overflow: hidden;
  max-height: calc(15dvh - 2rem);
  margin-top: 2rem;
  /* position: sticky; */

  
}

.socialMediaLink {
  color: white;
  max-width: 20%;
  flex: 1;
  position: relative;
  display: flex;
}


.socialMediaLinkIcon {
  position: relative;
  border-radius: 50%;
  /* display: block; */
  /* object-fit: scale-down; */
  transform: scale(0.3);
  
}

.socialMediaLinkIcon:hover {
  transform:scale(0.20);
  filter:brightness(200%);
  filter:drop-shadow(0 0 0.75rem rgba(106, 250, 255, 0.8));

}

@keyframes titleAnimation {
  0% {
    text-shadow: -2px 0 rgba(255, 255, 255, 0.5), 0 2px rgba(5, 176, 255, 0.1),
      2px 0 rgba(5, 176, 255, 0.1), 0 -2px rgba(5, 176, 255, 0.1);
  }
  25% {
    text-shadow: -2px 0 rgba(5, 176, 255, 0.1), 0 2px rgba(255, 255, 255, 0.5),
      2px 0 rgba(5, 176, 255, 0.1), 0 -2px rgba(5, 176, 255, 0.1);
  }
  50% {
    text-shadow: -2px 0 rgba(25, 176, 255, 0.1), 0 2px rgba(5, 176, 255, 0.1),
      2px 0 rgba(255, 255, 255, 0.5), 0 -2px rgba(5, 176, 255, 0.1);
  }
  75% {
    text-shadow: -2px 0 rgba(5, 176, 255, 0.1), 0 2px rgba(5, 176, 255, 0.1),
      2px 0 rgba(5, 176, 255, 0.1), 0 -2px rgba(255, 255, 255, 0.5);
  }
  100% {
    text-shadow: -2px 0 rgba(255, 255, 255, 0), 0 2px rgba(5, 176, 255, 0),
      2px 0 rgba(5, 176, 255, 0), 0 -2px rgba(5, 176, 255, 0);
  }
}

@media (orientation: portrait), (max-aspect-ratio: 720/721) {
  .App {
    flex-direction: column;
  }
  h1 {
    font-size: 6rem;
  }

  .container_home {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100dvh - 15dvh - 4rem);
    overflow-y: auto;
    gap: 1.5rem;
  }
  .mainProfilePhoto {
    margin-top: 1rem;
    height: 35dvh;
  }

  .container_title-ultrawide {
    display: none;
  }


  .container_external-links {
    display: flex;
    bottom: 1rem;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 32px;
    height: 15dvh;
    margin: 0;
    flex-grow: 0;
    z-index: 10;
    padding: 1rem;
    overflow: hidden;
    max-height: calc(15dvh - 2rem);
    margin-top: 2rem;
    
  }
  .socialMediaLinkIcon {
    transform: scale(0.7);
  }
  .socialMediaLinkIcon:hover {
    transform: scale(0.5);
  }
}

@media (max-width: 850px) {
 html {
  font-size: 50%;
 }
 h1 {
  font-size: 5rem;
 }
h2 {
  font-size: 2rem;
}
/* .container_main-blurb {
  
  max-height: calc(30vh - 2rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.mainProfilePhoto {
  margin-top: 1rem;
} */
}

@media (max-width: 410px) {
  .container_main-blurb {
    max-height: 25dvh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
@media (max-height: 700px) { 
  h1{ 
    font-size: 3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  .container_main-image {
    height: 20dvh;
  }
  .mainProfilePhoto {
    max-height: 100%;
  }

}

@media (orientation: landscape), (min-aspect-ratio: 16/9) {
  .container_home {
    padding: 1rem; 
    margin-bottom: 1rem; 
    max-height: 100dvh; 
    overflow-y: auto; 
  }

  .mainProfilePhoto {
    max-height: 50dvh; 
  }

  /* .container_main-blurb {
    padding: 1rem;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 30vh; 
  } */

  .container_external-links {
    height: 10dvh; 
    padding: 0.5rem;
    max-height: calc(10dvh - 1rem);
    overflow: hidden;
  }

}

@media (max-height: 900px) {
  .container_home {
    padding: 1rem;
    gap: 1rem;
    max-height: calc(100dvh - 10dvh); 
  }
  
  .mainProfilePhoto {
    margin-top: 0.5rem;
    max-height: 30dvh; 
  }

  /* .container_main-blurb {
    flex-grow: 1;
    align-items: stretch;
    height: auto;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  } */

  .container_main-image {
    flex-grow: 1;
    
  }

  .mainProfilePhoto {
    max-height: 58dvh;
  }



  .container_external-links {
    height: 10dvh;
  }
  .socialMediaLinkIcon {
    transform: scale(0.25);
  }

  .socialMediaLinkIcon:hover {
    transform: scale (0.20);
  }
}


@media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 16/10) {
  /* .container_main-blurb {
    flex-grow: 1;
    align-items: stretch;
    height: auto;
    max-height: 70vh;
    align-items: center;  
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}  */
  .container_home {
    align-items: space-between;
    justify-content: center;
  }
}

@media (min-aspect-ratio: 16/9) and (min-width:720px) {

  html {
    overflow: hidden;
  }
  .App {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8rem;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: inset 0 0 30px 10px rgba(255,255,255,0.2);
    max-height: 90dvh;
  }
  .container_home {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    max-height: 80dvh;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 0;
  }

  .container_main-image {
    flex-grow: 1;
    flex-basis: 0;
    align-self: flex-start;
  
  }


  .mainProfilePhoto {
    width: 100%;
    object-fit: contain;
  }


  h2 {
    font-size: 3rem;
  }

  /* .container_main-blurb {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    height: auto;
    max-height: 80vh;
    min-height: 50vh;
  } */


  .socialMediaLinkIcon {
    transform: scale(0.6);
  }

  .socialMediaLinkIcon:hover {
    transform: scale(0.4);
  }
  .container_title-normal {
    display: fixed;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
   
  }
  h1 {
    font-size: 6rem;
  }

  .container_external-links {
    display: flex;
    flex-direction: row;
    justify-content: row;
    align-items: center;
    height: 18dvh;
    padding: 1rem;
    overflow: hidden;
   max-height: calc(10dvh - 1rem);
  }
  .socialMediaLink {
    flex: 1;
    max-width: none;
    justify-content: center;  
}
  .socialMediaLinkIcon {
    transform: scale(0.18);
  }
  .socialMediaLinkIcon:hover {
    transform: scale(0.14);
  }
  .ws {
    display: inline;
  }
  .socialblurb_ws {
    padding-left: 5rem;
  }
}
